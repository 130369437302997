import { makeAutoObservable, runInAction } from "mobx";
import agent from "../utils/agent";
import { store } from "./store";
import { HealthInsuranceDto } from "../data/models/healthInsurance";

export class MasterDataStore {
    healthInsuranceOptions: Map<string | null, HealthInsuranceDto> = new Map<string, HealthInsuranceDto>();

    constructor() {
        makeAutoObservable(this);
    }

    get healthInsuranceOptionsList() {
        return Array.from(this.healthInsuranceOptions.values());
    }

    getHealthInsuranceOptions = async () => {
        store.loadingStore.startLoading(this.getHealthInsuranceOptions);
        try {
            const healthInsuranceOptions: HealthInsuranceDto[] = await agent.MasterData.getHealthInsuranceOptions();

            runInAction(() => {
                healthInsuranceOptions.forEach(option => {
                    option.value = option.name!;
                    this.healthInsuranceOptions.set(option.name, option);
                })
            })
            store.loadingStore.stopLoading(this.getHealthInsuranceOptions);
        } catch (error) {
            store.loadingStore.stopLoading(this.getHealthInsuranceOptions);
            throw error;
        }
    }

    reset() {
        this.healthInsuranceOptions?.clear();
    }
}
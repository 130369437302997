import { makeAutoObservable, runInAction } from "mobx";
import { InformConsent } from "../data/models/client";
import agent from "../utils/agent";
import { store } from "./store";
import { DocumentOriginalityDto } from "../data/models/session";

export class ClientInformedConsentStore {
    consent: InformConsent = {} as InformConsent;
    informConsents: Map<number, InformConsent> = new Map();

    constructor() {
        makeAutoObservable(this);
    }

    setConsent(consent: InformConsent) {
        this.consent = consent;
    }

    setInformedConsent(order: number, consent: InformConsent) {
        this.informConsents.set(order, consent);
    }

    get informedConsentsList(): InformConsent[] {
        return Array.from(this.informConsents.values());
    }

    clearInformedConsents() {
        this.informConsents?.clear();
    }

    getInformConsents = async (clientId: string) => {
        store.loadingStore.startLoading(this.getInformConsents);
        try {
            const response = await agent.ClientInformConsent.getInformConsents(clientId);
            runInAction(() => {
                this.clearInformedConsents()
                response.forEach((consent: InformConsent) => {
                    this.setInformedConsent(consent.order, consent);
                });
            });
        } catch (err) {
            throw err;
        } finally {
            store.loadingStore.stopLoading(this.getInformConsents);
        }
    }

    setDocumentOriginality = async (fileSessionId: string, documentOriginalityDto: DocumentOriginalityDto) => {
        store.loadingStore.startLoading(this.setDocumentOriginality);
        try {
            await agent.ClientInformConsent.setSessionFileOriginality(fileSessionId, documentOriginalityDto);
        }
        catch (err) {
            throw err;
        }
        finally {
            store.loadingStore.stopLoading(this.setDocumentOriginality);
        }
    }

    reset() {
        this.consent = {} as InformConsent;
        this.informConsents.clear();
    }
}


import { Dayjs } from 'dayjs';
import { RRule } from 'rrule';
import { AttendeeDto } from './user';

export interface Event {
    id: string,
    title: string,
    attendees: string[],
    isAllDay: boolean,
    allPms: boolean,
    isScreening: boolean,
    start: Date,
    startTime: Dayjs,
    end: Date,
    endTime: Dayjs,
    reminders: Reminder[],
    description: string
    frequency: number,
    recurrence: Recurrence,
    roomId: string | null,
    eventType: string,
    subType?: string,
    eventTypeId: string,
}

export interface ClientEvent {
    title: string,
    attendees: string[],
    isAllDay: boolean,
    start: Date,
    end: Date,
    reminders: Reminder[],
    description: string
    frequency: number,
    recurrence: Recurrence,
    roomId: string | null,
    eventType: string,
    eventTypeId: string,
}

export interface EventDetailsDto {
    id: string,
    title: string,
    attendeeEmails: string[],
    isAllDay: boolean,
    allPMs: boolean,
    isScreening: boolean,
    start: Date,
    end: Date,
    description: string
    recurrence: Recurrence | string,
    reminders: Reminder[],
    room: EventRoomDto,
    eventTypeId: string,
    subType: string;
    frequency: number,
}

export interface EventRoomDto {
    id: string,
    name: string,
}

export interface CalendarRoom {
    id: string,
    name: string,
    email: string,
}

export interface SubType {
    assessment: { name: string }[],
    intervention: { name: string }[],
}

export interface Reminder {
    value: number | string;
    unit: string;
}

export interface EventUpdateDto {
    id: string,
    title: string,
    start: Date,
    end: Date,
    description: string,
    isAllDay: boolean,
    isScreening: boolean,
    attendees: string[] | AttendeeDto[],
    roomId: string | null,
    eventTypeId: string,
    reminders: Reminder[],
    subType?: string,
    recurrence: Recurrence,
    timeZone: string
}

export interface EventCreateDto {
    title: string,
    start: Date,
    end: Date,
    description: string
    isAllDay: boolean,
    isScreening: boolean,
    roomId: string | null,
    eventType: string,
    attendees: string[] | AttendeeDto[],
    recurrence: Recurrence,
    reminders: Reminder[],
    subType?: string,
    institutionId: string,
    IsConfirmed: boolean,
    timeZone: string
}

export interface ClientEvent {
    title: string,
    attendees: string[],
    isAllDay: boolean,
    start: Date,
    end: Date,
    reminders: Reminder[],
    description: string
    frequency: number,
    recurrence: Recurrence,
    roomId: string | null,
    eventType: string,
}

export interface CalendarEvent {
    id: string,
    title: string,
    isAllDay: boolean,
    attendees: string[],
    start: Date,
    startTime: Dayjs,
    end: Date,
    endTime: Dayjs,
    reminder: string,
    description: string
    frequency: number,
    recurrence: string,
    eventExceptions: string[],
    rruleObject?: RRule | null,
    roomName: string,
    eventType: string,
    eventStatus: string,
    resource?: string,
    viewId: string,
    tenantName: string,
    attendeesStatus: AttendeeStatus[],
    isConfirmed: boolean
    room: string,
    roomNotes: string
}

export interface AttendeeStatus {
    id: string;
    email?: string;
    firstName: string;
    lastName: string;
    status: string;
    showedUp: boolean;
}

export enum EventStatus {
    Accepted = "CONFIRMED",
    Pending = "PENDING",
    Declined = "DECLINED"
}

export enum AssessorEventStatus {
    DECLINED = "DECLINED",
    CONFIRMED = "CONFIRMED",
    PENDING = "PENDING",
}
export interface Recurrence {
    /**
    * Type requires a number parameter that represents frequency:
    * 
    * NOREPEAT = 9,
    * YEARLY = 0,
    * MONTHLY = 1,
    * WEEKLY = 2,
    * DAILY = 3,
    * HOURLY = 4,
    * MINUTELY = 5,
    * SECONDLY = 6
    */
    type: number,
    startDate?: Date,
    endDate?: Date,
    interval?: number,
    byDays?: string[] | null,
    day?: number | null,
    byMonthDay?: number | null
}

export interface EventType {
    id: string,
    name: string,
    translatedName: string
}

export interface EventTypeDto {
    id: string,
    name: string | JSX.Element,
}

export enum View {
    USER = 'user',
    ROOM = 'room'
}

export interface CalendarView {
    id: string,
    type: View,
}

export interface Helper {
    id: string,
    name: string,
    email: string
}

export interface EventStatusDto {
    token: string | null;
    status: string | null;
}
export interface ConfirmationEventStatusDto {
    token: string;
    status: string;
    userId: string;
    eventId: string;
}

export interface EventDeleteDto {
    eventId: string,
    eventDate?: Date,
    startDate: Date,
}

export interface OtherCalendarEvent {
    startTime: Date,
    endTime: Date,
    eventType: string,
    recurrence: string,
    eventExceptions: string[],
    rruleObject?: RRule | null,
    isAllDay: boolean,
}

export interface EventDisplay {
    currentInstitutionEvents: CalendarEvent[],
    differentInstitutionEvents: OtherCalendarEvent[]
}

export interface PMSessionEventToCreate {
    type: string,
    startDate: Date,
    endDate: Date,
    hasConflict: boolean
}

export interface EventConfirmDto {
    status: string;
}
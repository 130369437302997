import { makeAutoObservable, runInAction } from "mobx";
import {
    Client,
    ClientAboutDto,
    ClientUpdateDto,
    TaskDetails,
    TaskCreateDto,
    Task, Timeline,
    ClientBasicInfoDto,
    ClientDetailsCreateDto,
    ClientQueryParams,
    ClientSessionTimelineOverviewItem,
    SessionCategory,
    ClientGeneralInfo,
} from "../data/models/client";
import agent from "../utils/agent";
import dayjs from "dayjs";
import { Pagination } from "../data/models/pagination";
import { RoleDto } from "../data/models/role";
import { priorityValue } from "../utils/helpers/clientHelper";
import { store } from "./store";

export default class ClientStore {
    clients: Map<string, Client> | undefined = new Map<string, Client>();
    tasks: Map<string, TaskDetails> | undefined = new Map<string, TaskDetails>();
    timeline: Map<number, Timeline> | undefined = new Map<number, Timeline>();
    clientRoles: Map<number, RoleDto> = new Map<number, RoleDto>();
    client: ClientAboutDto | null = null;
    generalInfo: ClientGeneralInfo | null = null;
    loading: boolean = false;
    ahvLoading: boolean = false;
    fieldLoading: boolean = false;
    _clientCreateId: string | null = null;
    brightTimeline: Map<string, ClientSessionTimelineOverviewItem> | undefined = new Map<string, ClientSessionTimelineOverviewItem>();

    constructor() {
        makeAutoObservable(this);
    }

    get clientList() {
        return Array.from(this.clients!.values());
    }

    get brightTimelineList() {
        return Array.from(this.brightTimeline!.values());
    }

    get taskList() {
        return Array.from(this.tasks!.values());
    }

    get timelineList() {
        return Array.from(this.timeline!.values())
    }

    get sessionStartDate() {
        return this.brightTimelineList[0]?.date || new Date();
    }

    get clientRoleList() {
        return Array.from(this.clientRoles.values());
    }

    clearTimeline = () => {
        this.timeline?.clear();
    }

    clearBrightTimeline = () => {
        this.brightTimeline?.clear();
    }

    clientCreateId = (id: string) => {
        this._clientCreateId = id;
    }

    clearClientId = () => {
        this._clientCreateId = null;
    };

    updateTaskState = (task: TaskDetails) => {
        this.tasks?.set(task.id, { ...task });
    };

    getClients = async (institutionId: string, queryParams: ClientQueryParams, isFilterData: boolean = false) => {
        store.loadingStore.startLoading(this.getClients);
        this.loading = true;
        try {
            const clientsReponse = await agent.Clients.get(institutionId, queryParams);

            runInAction(() => {
                if (isFilterData) {
                    this.clients!.clear()
                };
                store.commonStore.setClientPagination(JSON.stringify(clientsReponse.pagination))
                clientsReponse.data.forEach((client) => {
                    this.clients?.set(client.id, client);
                });

                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getClients);
        } catch (error) {
            runInAction(() => (this.loading = false));
            store.loadingStore.stopLoading(this.getClients);
            throw error;
        }
    };

    getClient = async (clientId: string, institutionId: string) => {
        store.loadingStore.startLoading(this.getClient);
        this.loading = true;
        try {
            const client = await agent.Clients.getClientInfo(clientId, institutionId);

            runInAction(() => {
                this.client = client;
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getClient);
        } catch (error) {
            runInAction(() => (this.loading = false));
            store.loadingStore.stopLoading(this.getClient);
            throw error;
        }
    };

    getClientDetails = async (clientId: string, institutionId: string) => {
        store.loadingStore.startLoading(this.getClientDetails);
        this.loading = true;
        try {
            const clientDetails = await agent.Clients.getClientDetails(clientId, institutionId);

            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getClientDetails);

            return clientDetails;
        } catch (error) {
            runInAction(() => (this.loading = false));
            store.loadingStore.stopLoading(this.getClientDetails);
            throw error;
        }
    };

    getClientById = async (clientId: string) => {
        store.loadingStore.startLoading(this.getClientById);
        this.loading = true;
        try {
            const client = await agent.Clients.getById(clientId);

            runInAction(() => {
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getClientById);

            return client;
        } catch (error) {
            runInAction(() => (this.loading = false));
            store.loadingStore.stopLoading(this.getClientById);
            throw error;
        }
    };

    createClient = async (client: ClientBasicInfoDto) => {
        store.loadingStore.startLoading(this.createClient);
        this.loading = true;
        try {
            const { id } = await agent.Clients.create(client);

            runInAction(() => {
                this._clientCreateId = id;
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.createClient);
        } catch (error) {
            runInAction(() => (this.loading = false));
            store.loadingStore.stopLoading(this.createClient);
            throw error;
        }
    };

    createClientDetails = async (clientDetails: ClientDetailsCreateDto) => {
        store.loadingStore.startLoading(this.createClientDetails);
        this.loading = true;
        try {
            await agent.Clients.createDetails(clientDetails);

            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.createClientDetails);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.createClientDetails);
            throw error;
        }
    }

    updateClient = async (client: ClientUpdateDto, institutionId: string) => {
        store.loadingStore.startLoading(this.updateClient);
        this.loading = true;
        try {
            if (this._clientCreateId) {
                await agent.Clients.update(this._clientCreateId, client, institutionId);
            }

            runInAction(() => (this.loading = false));
            store.loadingStore.stopLoading(this.updateClient);
        } catch (error) {
            runInAction(() => (this.loading = false));
            store.loadingStore.stopLoading(this.updateClient);
            throw error;
        }
    };

    updateClientDetails = async (clientDetails: ClientDetailsCreateDto) => {
        store.loadingStore.startLoading(this.updateClientDetails);
        this.loading = true;
        try {
            if (this._clientCreateId) {
                await agent.Clients.updateDetails(this._clientCreateId, clientDetails);
            }
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.updateClientDetails);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.updateClientDetails);
            throw error;
        }
    };

    updateClientFields = async (clientId: string, data: any) => {
        store.loadingStore.startLoading(this.updateClientFields);
        try {
            await agent.Clients.updateFields(clientId, data);
            store.loadingStore.stopLoading(this.updateClientFields);
        } catch (error) {
            store.loadingStore.stopLoading(this.updateClientFields);
            throw error;
        }
    }

    viewTaskFile = async (taskId: string) => {
        store.loadingStore.startLoading(this.updateClientFields);
        try {
            const response = await agent.Clients.viewTaskFile(taskId);
            store.loadingStore.stopLoading(this.updateClientFields);

            return response;
        } catch (error) {
            store.loadingStore.stopLoading(this.updateClientFields);
            throw error;
        }
    }

    deleteClient = async (clientId: string) => {
        store.loadingStore.startLoading(this.deleteClient);
        try {
            const institution = store.institutionStore.selectedUserInstitution;
            if (institution) {
                await agent.Clients.delete(clientId, institution.institutionId);
            }
            runInAction(() => {
                this.clients!.delete(clientId);
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.deleteClient);
        } catch (error) {
            runInAction(() => (this.loading = false));
            store.loadingStore.stopLoading(this.deleteClient);
            throw error;
        }
    };

    getTimeline = async (userId: string, paging?: Pagination) => {
        store.loadingStore.startLoading(this.getTimeline);
        this.loading = true;
        try {
            const timelineItems = await agent.Clients.getTimeline(userId, paging);

            runInAction(() => {
                let startIndex = this.timeline!.size;
                timelineItems.data.forEach((item, index) => {
                    this.timeline!.set(startIndex + index, item)
                });
                this.loading = false;
            })
            store.loadingStore.stopLoading(this.getTimeline);
        } catch (error) {
            runInAction(() => (this.loading = false));
            store.loadingStore.stopLoading(this.getTimeline);
            throw error;
        }
    }

    fetchTimeline = async (clientId: string, institutionId: string, sessionId: string) => {
        store.loadingStore.startLoading(this.fetchTimeline);
        this.loading = true;
        try {
            const clientSessionTimelineOverviewDetails = await agent.Clients.getClientSessionsTimelineOverview(clientId, institutionId, sessionId);

            const updatedClientSessionTimelineOverviewDetails = clientSessionTimelineOverviewDetails.map((item, index) => ({
                ...item,
                id: index + 1 + "",
            }));

            runInAction(() => {
                this.brightTimeline?.clear();
                updatedClientSessionTimelineOverviewDetails.forEach((row) => {
                    this.brightTimeline?.set(row.id, row);
                });
            });
            store.loadingStore.stopLoading(this.getClients);
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => {
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.fetchTimeline);
        }
    }

    getClientRoles = async (tenantId: string) => {
        store.loadingStore.startLoading(this.getClientRoles);
        try {
            const roles = await agent.Clients.getRoles(tenantId);
            runInAction(() => {
                this.clientRoles.clear();
                roles.forEach((role) => this.clientRoles.set(role.id, role));
            });
            store.loadingStore.stopLoading(this.getClientRoles);
        } catch (error) {
            store.loadingStore.stopLoading(this.getClientRoles);
            throw error;
        }
    }

    getClientTasks = async (clientId: string) => {
        store.loadingStore.startLoading(this.getClientTasks);
        this.loading = true;
        try {
            const tasks = (await agent.Clients.getTasks(clientId)).data;

            runInAction(() => {
                this.tasks?.clear();
                if (tasks.length > 0) {
                    tasks
                        .sort((a, b) => {
                            const dateA = dayjs(a.dueDate).startOf('day');
                            const dateB = dayjs(b.dueDate).startOf('day');
                            const dueDateComparison = dateA.toDate().getTime() - dateB.toDate().getTime();

                            if (dueDateComparison === 0) {
                                return priorityValue(a.priority) - priorityValue(b.priority);
                            }

                            return dueDateComparison;
                        })
                        .forEach((task) => this.tasks?.set(task.id, task));
                }
                this.loading = false;

            });
            store.loadingStore.stopLoading(this.getClientTasks);
        } catch (error) {
            runInAction(() => (this.loading = false));
            store.loadingStore.stopLoading(this.getClientTasks);
            throw error;
        }
    };

    getClientTask = async (taskId: string) => {
        store.loadingStore.startLoading(this.getClientTask);
        try {
            const task = await agent.Clients.getTask(taskId);
            store.loadingStore.stopLoading(this.getClientTask);

            return task;
        } catch (error) {
            store.loadingStore.stopLoading(this.getClientTask);
            throw error;
        }
    };

    updateAhvNumber = async (clientId: string, ahvNumber: string) => {
        store.loadingStore.startLoading(this.updateAhvNumber);
        this.ahvLoading = true;
        try {
            await agent.Clients.setAhvNumber(clientId, ahvNumber);

            runInAction(() => this.ahvLoading = false);
            store.loadingStore.stopLoading(this.updateAhvNumber);
        } catch (error) {
            runInAction(() => this.ahvLoading = false);
            store.loadingStore.stopLoading(this.updateAhvNumber);
            throw error;
        }
    }

    updateAgencyName = (clientId: string, agencyName: string) => {
        const data = [{ op: "replace", path: "/ContactAgencyName", value: agencyName }];
        return this.updateClientFields(clientId, data);
    };

    updateAgencyPhoneNumber = (clientId: string, phoneNumber: string) => {
        const data = [{ op: "replace", path: "/ContactAgencyPhone", value: phoneNumber }];
        return this.updateClientFields(clientId, data);
    };

    updateAgencyEmail = (clientId: string, email: string) => {
        const data = [{ op: "replace", path: "/ContactAgencyEmail", value: email }];
        return this.updateClientFields(clientId, data);
    };

    createTask = async (task: TaskCreateDto, files: File[]) => {
        store.loadingStore.startLoading(this.createTask);
        this.loading = true;
        try {
            await agent.Clients.createTask(task, files);
            runInAction(() => (this.loading = false));
            store.loadingStore.stopLoading(this.createTask);
        } catch (error) {
            runInAction(() => (this.loading = false));
            store.loadingStore.stopLoading(this.createTask);
            throw error;
        }
    };

    updateTask = async (taskId: string, task: Task, files: File[]) => {
        store.loadingStore.startLoading(this.updateTask);
        this.loading = true;
        try {
            await agent.Clients.updateTask(taskId, task, files);
            runInAction(() => (this.loading = false));
            store.loadingStore.stopLoading(this.updateTask);
        } catch (error) {
            runInAction(() => (this.loading = false));
            store.loadingStore.stopLoading(this.updateTask);
            throw error;
        }
    };

    updateTaskStatus = async (taskId: string) => {
        store.loadingStore.startLoading(this.updateTaskStatus);
        try {
            await agent.Clients.updateTaskStatus(taskId);
            store.loadingStore.stopLoading(this.updateTaskStatus);
        } catch (error) {
            store.loadingStore.stopLoading(this.updateTaskStatus);
            throw error;
        }
    };

    deleteTask = async (taskId: string) => {
        store.loadingStore.startLoading(this.deleteTask);
        this.loading = true;
        try {
            await agent.Clients.deleteTask(taskId);
            runInAction(() => (this.loading = false));
            store.loadingStore.stopLoading(this.deleteTask);
        } catch (error) {
            runInAction(() => (this.loading = false));
            store.loadingStore.stopLoading(this.deleteTask);
            throw error;
        }
    }

    getAssessors = async (institutionId: string) => {
        store.loadingStore.startLoading(this.getAssessors);
        this.loading = true;
        try {
            const assessors = await agent.Clients.assessorList(institutionId);

            runInAction(() => (this.loading = false));
            store.loadingStore.stopLoading(this.getAssessors);
            return assessors;
        } catch (error) {
            runInAction(() => (this.loading = false));
            store.loadingStore.stopLoading(this.getAssessors);
            throw error;
        }
    }

    getSessionsOnClientDetail = async (clientId: string, institutionId: string) => {
        store.loadingStore.startLoading(this.getSessionsOnClientDetail);
        try {
            const clientSessions = await agent.Clients.getClientDetailSessions(clientId, institutionId);
            store.loadingStore.stopLoading(this.getSessionsOnClientDetail);
            return clientSessions;
        } catch (error) {
            store.loadingStore.stopLoading(this.getSessionsOnClientDetail);
            throw error;
        }
    }

    getGeneraInfo = async (clientId: string) => {
        store.loadingStore.startLoading(this.getGeneraInfo);
        try {
            const data = await agent.Clients.getGeneralInfo(clientId);
            this.generalInfo = data;
        }
        catch (error) {
            throw error;
        }
        finally {
            store.loadingStore.stopLoading(this.getGeneraInfo);
        }
    }

    reset(): void {
        this.clients?.clear();
        this.tasks?.clear();
        this.timeline?.clear();
        this.clientRoles?.clear();
        this.client = null;
        this.generalInfo = null;
        this.loading = false;
        this.ahvLoading = false;
        this.fieldLoading = false;
        this._clientCreateId = null;
        this.brightTimeline?.clear();
    }
}

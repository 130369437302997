import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';

interface EmptyStateProps {
    title: string;
    description: string;
    icon: SvgIconComponent;
}

const EmptyState: React.FC<EmptyStateProps> = ({ title, description, icon: Icon }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            padding={2}
            width="100%"
        >
            <IconButton color="primary" size="large">
                <Icon fontSize="large" />
            </IconButton>
            <Typography variant="h4" color="textPrimary" gutterBottom>
                {title}
            </Typography>
            <Typography variant="body1" color="textSecondary">
                {description}
            </Typography>
        </Box>
    );
};

export default EmptyState;

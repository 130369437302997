import { createContext, useContext } from "react";

import CommonStore from "./commonStore";
import RoleStore from "./roleStore";
import UserStore from "./userStore";
import SidebarStore from "./sidebarStore";
import TenantStore from "./tenantStore";
import InstitutionStore from "./institutionStore";
import ModalStore from "./modalStore";
import BookingStore from "./bookingStore";
import RoomStore from "./roomStore";
import AuthStore from "./authStore";
import TotpStore from "./totpStore";
import { LanguageStore } from "./languageStore";
import { ProfileStore } from "./profileStore";
import { DialogStore } from "./dialogStore";
import ClientStore from "./clientStore";
import SurveyStore from "./surveyStore";
import { ProcessStore } from "./processStore";
import { SessionStore } from "./sessionStore";
import { DataExportStore } from "./dataExportStore";
import { LoadingStore } from "./loadingStore";
import { MasterDataStore } from "./masterDataStore";
import AuditLogsStore from "./auditLogsStore";
import TemplateStore from "./templateStore";
import NotificationLogsStore from "./notificationLogStore";
import StudyOverviewStore from "./studyOverviewStore";
import { ClientInformedConsentStore } from "./clientInformedConsentStore";

interface Store {
    userStore: UserStore;
    authStore: AuthStore;
    totpStore: TotpStore;
    commonStore: CommonStore;
    roleStore: RoleStore;
    sidebarStore: SidebarStore;
    tenantStore: TenantStore;
    institutionStore: InstitutionStore;
    modalStore: ModalStore;
    bookingStore: BookingStore;
    roomStore: RoomStore;
    languageStore: LanguageStore;
    profileStore: ProfileStore;
    dialogStore: DialogStore;
    clientStore: ClientStore;
    surveyStore: SurveyStore;
    processStore: ProcessStore;
    sessionStore: SessionStore;
    dataExportStore: DataExportStore;
    loadingStore: LoadingStore;
    auditLogStore: AuditLogsStore;
    notificationLogStore: NotificationLogsStore;
    masterDataStore: MasterDataStore;
    templateStore: TemplateStore;
    studyOverviewStore: StudyOverviewStore;
    clientInformConsentStore: ClientInformedConsentStore;
    reset: () => void;
}

// TODO: Implement a reset() function on all the stores to clear the state upon logout
export const store: Store = {
    userStore: new UserStore(),
    authStore: new AuthStore(),
    totpStore: new TotpStore(),
    commonStore: new CommonStore(),
    roleStore: new RoleStore(),
    sidebarStore: new SidebarStore(),
    tenantStore: new TenantStore(),
    institutionStore: new InstitutionStore(),
    modalStore: new ModalStore(),
    bookingStore: new BookingStore(),
    roomStore: new RoomStore(),
    languageStore: new LanguageStore(),
    profileStore: new ProfileStore(),
    dialogStore: new DialogStore(),
    clientStore: new ClientStore(),
    surveyStore: new SurveyStore(),
    processStore: new ProcessStore(),
    sessionStore: new SessionStore(),
    dataExportStore: new DataExportStore(),
    loadingStore: new LoadingStore(),
    auditLogStore: new AuditLogsStore(),
    masterDataStore: new MasterDataStore(),
    templateStore: new TemplateStore(),
    notificationLogStore: new NotificationLogsStore(),
    studyOverviewStore: new StudyOverviewStore(),
    clientInformConsentStore: new ClientInformedConsentStore(),
    reset() {
        this.userStore.reset();
        this.authStore.reset();
        this.commonStore.clearSession();
        this.roleStore.reset();
        this.tenantStore.reset();
        this.institutionStore.reset();
        this.bookingStore.reset();
        this.roomStore.reset();
        this.languageStore.reset();
        this.clientStore.reset();
        this.surveyStore.reset();
        this.processStore.reset();
        this.sessionStore.reset();
        this.dataExportStore.reset();
        this.auditLogStore.reset();
        this.masterDataStore.reset();
        this.notificationLogStore.reset();
        this.studyOverviewStore.reset();
        this.clientInformConsentStore.reset();
    }
};


export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}
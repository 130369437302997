import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import FormInputFile from "../../../../../components/form/FormInputFile";
import { RoundButton } from "../../../../../_styles/StyledButtons";
import { useStore } from "../../../../../stores/store";
import { useParams } from "react-router-dom";
import CloudFileInfo from "../../../../../components/CloudFileInfo";
import { SessionFile } from "../../../../../data/models/session";
import { observer } from "mobx-react-lite";
import { Trans, useTranslation } from "react-i18next";
import { FormInputText } from "../../../../../components/form/FormInputText";

interface SessionFileFormProps {
    uploadedFiles: SessionFile[];
    order: number;
    sessionIdParam?: string;
}

function SessionFileForm({ uploadedFiles, order, sessionIdParam }: SessionFileFormProps) {
    const { t } = useTranslation();
    const { sessionStore, modalStore, clientInformConsentStore } = useStore();
    const {
        uploadConsent,
        loadAssessorSession,
        loadingFileUpload
    } = sessionStore;
    const { closeModal } = modalStore;
    const { getInformConsents } = clientInformConsentStore;
    const [files, setFiles] = useState<File[]>([]);
    const [fileError, setFileError] = useState(false);
    const [currentFiles, setCurrentFiles] =
        useState<SessionFile[]>(uploadedFiles);

    const { handleSubmit, control } = useForm<any>();
    const { id } = useParams();

    const params = useParams();
    const sessionId = params?.sessionId || sessionIdParam;


    const onSubmit = async (data: any) => {
        if (files.length === 0) {
            setFileError(true);
            return;
        }

        setFileError(false);

        if (!sessionId) {
            return;
        }

        // const uploadedFiles = await getConsentFiles(sessionId);
        // let firstIC = uploadedFiles.find((f) => f.order === 0);
        // let secondIC = uploadedFiles.find((f) => f.order === 1);

        // const updateConsentFile = async (fileId: string, file: any) => {
        //     await updateConsentFiles(sessionId, fileId, file);
        // };


        // if (firstIC && order === 0) {
        //     await updateConsentFile(firstIC.id, files[0]);
        // } else if (secondIC && order === 1) {
        //     await updateConsentFile(secondIC.id, files[0]);
        // } else {
        await uploadConsent(sessionId, order, files[0], data.notes ?? "");
        // const uploadedFiles = await getConsentFiles(sessionId);
        // firstIC = uploadedFiles.find((f) => f.order === 0);
        // secondIC = uploadedFiles.find((f) => f.order === 1);
        // }

        await loadAssessorSession(sessionId);
        if (id) { await getInformConsents(id) };
        closeModal();
    };

    const getFileUploadFormTile = (order: number) => {
        const orderToTitleMap: { [key: number]: string } = {
            0: 'FIRST_IC_UPLOAD',
            1: 'SECOND_IC_UPLOAD',
            2: 'THIRD_IC_UPLOAD'
        };

        return orderToTitleMap[order] || '';
    }

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid container columnSpacing={1}>
                <Grid item xs={12} mb={1}>
                    <Box sx={{ bgcolor: "#1976d217", p: "4px", borderRadius: "4px" }}>
                        <Typography sx={{ color: "#1976d2", fontSize: "0.75rem" }}>
                            <Trans i18nKey={"IC_UPLOAD_WARN"} />
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography fontWeight={600}>
                        {t(getFileUploadFormTile(order))}
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
                    <CloudFileInfo
                        files={currentFiles}
                        setFiles={(newFiles) => {
                            const files = newFiles.map((f) => {
                                const sessionFile: SessionFile = {
                                    order:
                                        currentFiles.find((file) => file.id === f.id)?.order ?? -1,
                                    id: f.id,
                                    fileName: f.fileName,
                                    size: f.size,
                                    url: f.url,
                                    isDeleted: f.isDeleted,
                                };

                                return sessionFile;
                            });

                            setCurrentFiles(files);
                        }}
                        canDelete={false}
                        displayStatuses={false}
                        fileType="SESSION"
                        canDownload={false}
                    />
                </Grid>
                {fileError && (
                    <Grid item xs={12}>
                        <Typography color="error" fontSize={"0.85rem"} pl={"8px"}>
                            {t("IC_FILE_ERROR")}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <FormInputFile files={files} setFiles={setFiles} limit={1} allowImages={true} />
                </Grid>
                <Grid item xs={12}>
                    <FormInputText
                        name="notes"
                        control={control}
                        label={`${t("GENERAL_NOTES")}`}
                        rows={2}
                    />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                    <Box display="flex" alignItems={"center"}>
                        <RoundButton
                            type="button"
                            variant="text"
                            sx={{ mr: 2, color: "#808080" }}
                            title="Cancel"
                            disableFocusRipple
                            disableTouchRipple
                            onClick={() => closeModal()}
                        >
                            {t("GENERAL_CANCEL")}
                        </RoundButton>
                        <RoundButton
                            type="submit"
                            variant="contained"
                            sx={{ alignSelf: "flex-end" }}
                            title="Submit"
                            disabled={loadingFileUpload}
                        >
                            {loadingFileUpload ? (
                                <CircularProgress size={25} />
                            ) : (
                                <Typography fontSize="13px">{t("GENERAL_UPLOAD")}</Typography>
                            )}
                        </RoundButton>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default observer(SessionFileForm);

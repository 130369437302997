import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Container } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import EmptyState from '../../../components/EmptyState';
import useFetch from '../../../components/hooks/useFetch';
import LoadingComponent from '../../../components/LoadingComponent';
import { useStore } from '../../../stores/store';
import { mapInformConsentsToSessionFiles } from '../../../utils/helpers/clientHelper';
import ConsentBox from './ConsentBox';

const ClientInformConsent: React.FC = () => {
    const { clientInformConsentStore, loadingStore } = useStore();
    const { isLoading } = loadingStore;
    const { getInformConsents, informedConsentsList } = clientInformConsentStore;
    const { t } = useTranslation();
    const { id } = useParams();

    useFetch(() => id && getInformConsents(id), [id, getInformConsents]);

    const getICFiles = (order: number) =>
        mapInformConsentsToSessionFiles(informedConsentsList).filter((f) => f.order === order);

    if (isLoading(getInformConsents)) return <LoadingComponent />;

    if (!informedConsentsList.length) return (
        <EmptyState
            title={t('NO_AVAILABLE_SESSIONS_TITLE')}
            description={t("NO_AVAILABLE_SESSIONS_DESCRIPTION")}
            icon={InfoOutlined}
        />
    );
    return (
        <Container maxWidth="md" sx={{ px: 0 }}>
            {informedConsentsList.map((consent) => (
                <ConsentBox
                    key={consent.order}
                    consent={consent}
                    getICFiles={getICFiles}
                    status={consent.documentOriginal}
                />
            ))}
        </Container>
    );
};

export default observer(ClientInformConsent);
import { Box, Container, Typography } from "@mui/material";
import ClientToDoItem from "./ClientToDoItem";
import { useLayoutEffect } from "react";
import Sidebar from "../../../components/sidebar/Sidebar";
import { RoundButton } from "../../../_styles/StyledButtons";
import { useStore } from "../../../stores/store";
import ToDoForm from "../Form/ToDoForm";
import { observer } from "mobx-react-lite";
import { TaskStatus } from "../../../data/models/client";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { useTranslation } from "react-i18next";

function ClientToDoList() {
    const { t } = useTranslation();
    const { sidebarStore, clientStore } = useStore();
    const { openSidebar, closeSidebar } = sidebarStore;
    const { taskList, client } = clientStore
    useLayoutEffect(() => {
        const clientContainer = document.querySelector('.client-data-container') as HTMLElement;

        clientContainer.style.height = 'auto';

        return () => {
            clientContainer.style.height = '100%';
            closeSidebar();
        }
    })

    const openForm = () => {
        openSidebar(<ToDoForm />, "Add task")
    }

    return (
        <Container maxWidth="md">
            <Box className="todo-list-container">
                <Box className="todo-list-header">
                    <Box className="todo-header-title">
                        <Typography fontSize="18px">{t("CLIENT_TASKS_TAB")}</Typography>
                        <Typography fontSize="13px" sx={{ marginLeft: '8px', color: '#848484' }}>
                            {taskList.filter(t => t.state === TaskStatus.INCOMPLETE).length} {t("CLIENT_TASK_REM")}
                        </Typography>
                    </Box>
                    <Box className="todo-list-actions">
                        {client !== null ?
                            <RoundButton variant="contained" onClick={openForm}>
                                <Typography fontSize="13px">{t("CLIENT_ADD_TASK")}</Typography>
                            </RoundButton>
                            : ''
                        }
                    </Box>
                </Box>
                <Box className="todo-list-items">
                    {taskList.length === 0 ?
                        <Box width="100%" display="flex" justifyContent="center" sx={{ borderTop: '1px solid #ddd', paddingTop: '20px' }}>
                            <Typography fontSize="14px" sx={{ color: '#848484' }}>{t("CLIENT_NO_TASKS")}</Typography>
                        </Box>
                        :
                        <>
                            {
                                taskList.map((todo, index) => (
                                    <ClientToDoItem key={index} todo={todo} />
                                ))
                            }
                        </>
                    }
                </Box>
                <ConfirmDialog />
                <Sidebar />
            </Box>
        </Container>
    );
}

export default observer(ClientToDoList);
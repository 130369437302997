import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

import { format } from "date-fns";

import ActivityLayout from "./ActivityLayout";
import { useStore } from "../../../stores/store";
import { useEffect, useState } from "react";
import { CalendarEvent } from "../../../data/models/event";
import ClientEvents from "./ClientEvents";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import ClientDetailSessionTable from "../Form/ClientDetailSessionTable";
import { ClientDetailSessionsDto } from "../../../data/models/client";
import { observer } from "mobx-react-lite";
import { SessionFile, SessionStatus } from "../../../data/models/session";
import i18n from "../../../data/translations/i18n";
import { getDateAndTimeFormatByPlatformLanguage, getDateFormatByPlatformLanguage } from "../../../utils/utils";
import ClientSessionTimelineOverview from "../Form/ClientSessionTimelineOverview/ClientSessionTimelineOverview";
import { UserStatus } from "../../../data/models/user";
import SessionATSBanner from "../../Screening/Process/Session/Dashboard/SessionATSBanner";
import CrisisAssessmentForm from "../../Screening/Process/Session/Forms/CrisisAssessmentForm";
import CrisisAlertRoundedIcon from "@mui/icons-material/CrisisAlertRounded";
import { AppRoles } from "../../../data/models/role";
import { hasRolesv2 } from "../../../utils/permissionEvaluator";

function ClientAbout() {
    const { t, i18n } = useTranslation();
    const { clientStore, bookingStore, institutionStore, loadingStore, sessionStore, modalStore } = useStore();
    const { client, getSessionsOnClientDetail, generalInfo, getGeneraInfo } = clientStore;
    const { getClientEvents } = bookingStore;
    const { selectedUserInstitution } = institutionStore
    const { loadAssessorSession, session, loading } = sessionStore;
    const navigate = useNavigate();
    const [events, setEvents] = useState<CalendarEvent[]>([]);
    const [sessions, setSessions] = useState<ClientDetailSessionsDto[]>([]);
    const [activeSessionId, setActiveSessionId] = useState<string | null>(null);
    const [ICFiles, setICFiles] = useState<SessionFile[]>([]);
    const { openModal, closeModal } = modalStore;

    useEffect(() => {
        const fetchEvents = async () => {
            if (client) {
                const events = await getClientEvents(client!.id!);
                setEvents(events!);
                const clientSessions = await getSessionsOnClientDetail(client.id, selectedUserInstitution?.institutionId!)

                const activeSession = clientSessions.find(s => s.status === 'ACTIVE' || s.status === 'AWAITING_EVENT_CREATION');

                if (activeSession) {
                    setActiveSessionId(activeSession.id);
                }

                setSessions(clientSessions);
            }
        };

        fetchEvents();
    }, [client, getClientEvents]);

    useEffect(() => {
        const fetchActiveSession = async () => {
            if (activeSessionId) {
                await loadAssessorSession(activeSessionId);
            }
        };

        fetchActiveSession();
    }, [activeSessionId])

    const handleSessionUpdate = async () => {
        setTimeout(async () => {
            if (client) {
                const clientSessions = await getSessionsOnClientDetail(client.id, selectedUserInstitution?.institutionId!)
                setSessions(clientSessions);
            }
        }, 400);
    };

    const onSurveyStart = async (surveyId: string) => {
        if (session) {
            await loadAssessorSession(session.sessionId);
            navigate(
                `/timeline/${session?.timelineId}/survey/${surveyId}/session/${session?.sessionId}?lang=${session?.languageId}&redirect=${`/users/${client?.id}/about`}`,
                {
                    state: {
                        redirect: `/users/${client?.id}/about`,
                    },
                }
            );
        }
    };

    return (
        <>
            <Box display="flex" flexDirection="column" sx={{ overflowY: 'scroll' }}>
                {
                    selectedUserInstitution &&
                    hasRolesv2([AppRoles.FIELD_COORDINATOR], selectedUserInstitution.roles) &&
                    session &&
                    session?.status !== SessionStatus.EMERGENCY_HOLD &&
                    session?.status !== SessionStatus.DEACTIVATED &&
                    session.timeline !== 'Baseline' &&
                    session.mustCompleteATSIntervention &&
                    !session.isAssessmentOfSuicideCompleted &&
                    <div
                        className="critical-alert"
                        style={{ borderRadius: '4px', color: 'white', padding: '0px 5px', marginTop: '20px', width: '95%' }}>
                        <p>{t('REQUIRES_ATS_ON_PM1')}</p>
                    </div>
                }
                {
                    selectedUserInstitution && hasRolesv2([AppRoles.USZ_COORDINATOR, AppRoles.HELPER], selectedUserInstitution.roles) &&
                    session &&
                    session?.status !== SessionStatus.EMERGENCY_HOLD &&
                    session?.status !== SessionStatus.DEACTIVATED &&
                    session.timeline !== 'Baseline' &&
                    session.mustCompleteATSIntervention && (
                        !session.isAssessmentOfSuicideCompleted ? <Box width="96%" marginY="20px">
                            <SessionATSBanner session={session} onSurveyStart={onSurveyStart} />
                        </Box> :
                            !session.isAssessmentOfSuicideFormCompleted && <Box width="96%" marginY="20px">
                                <Button
                                    onClick={() => {
                                        openModal(
                                            <CrisisAssessmentForm sessionId={session?.sessionId!} />,
                                            { size: "xs" }
                                        );
                                    }}
                                    variant="contained"
                                    color="error"
                                    sx={{ width: "auto", fontSize: "12px", mr: 1 }}
                                >
                                    <CrisisAlertRoundedIcon fontSize="small" sx={{ mr: "4px" }} />
                                    {t("SESSION_ATS_CON")}
                                </Button>
                            </Box>
                    )
                }
                <Box className="about-container">
                    <ActivityLayout
                        showFlag={false}
                        className="general-information-container"
                    >
                        <Box className="general-information">
                            <Box className="general-information-header">
                                <Typography fontSize="20px" fontWeight="500">
                                    {t("CLIENT_GENERAL_INFO")}
                                </Typography>
                                {
                                    !loadingStore.isLoading(getGeneraInfo) &&
                                        (generalInfo?.status !== UserStatus.ANON) && client !== null && !selectedUserInstitution?.roles.some(r => ["HELPER", "CLIENT"].includes(r.toUpperCase())) ?
                                        <IconButton disabled={loadingStore.isLoading(getGeneraInfo)} onClick={() => navigate(`/users/${client!.id}/update`)}>
                                            <EditIcon sx={{ fontSize: "18px" }} />
                                        </IconButton>
                                        : ''
                                }
                            </Box>
                            <Box className="client-personal-info">
                                <Box className="about-information">
                                    <Typography fontSize="16px" fontWeight={500}>
                                        {t("USER_FULL_NAME")}
                                    </Typography>
                                    <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                        {generalInfo?.fullName ?? "No information."}
                                    </Typography>
                                </Box>
                                <Box className="about-information">
                                    <Typography fontSize="16px" fontWeight={600}>
                                        {t("USER_BIRTHDATE")}
                                    </Typography>
                                    <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                        {generalInfo?.birthDate ?
                                            getDateFormatByPlatformLanguage(new Date(generalInfo.birthDate), i18n.language) :
                                            "No information."}
                                    </Typography>
                                </Box>
                                {/*TODO: Use a list map for the items below!*/}
                                <Box className="about-information">
                                    <Typography fontSize="16px" fontWeight={500}>
                                        {t("USER_GENDER")}
                                    </Typography>
                                    <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                        {generalInfo?.gender ?? "No information."}
                                    </Typography>
                                </Box>
                                <Box className="about-information">
                                    <Typography fontSize="16px" fontWeight={500}>
                                        {t("USER_EMAIL")}
                                    </Typography>
                                    <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                        {generalInfo?.email ?? "No information."}
                                    </Typography>
                                </Box>
                                <Box className="about-information">
                                    <Typography fontSize="16px" fontWeight={500}>
                                        {t("GENERAL_PHONE_NUMBER")}
                                    </Typography>
                                    <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                        {generalInfo?.phoneNumber ?? "No information."}
                                    </Typography>
                                </Box>
                                <Box className="about-information">
                                    <Typography fontSize="16px" fontWeight={600}>
                                        {t("CLIENT_HEALTH_INSURANCE")}
                                    </Typography>
                                    <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                        {generalInfo?.healthInsurance ?? "No information."}
                                    </Typography>
                                </Box>
                                <Box className="about-information">
                                    <Typography fontSize="16px" fontWeight={600}>
                                        {t("CLIENT_AHV")}
                                    </Typography>
                                    <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                        {client?.ahvNumber ?? "No information."}
                                    </Typography>
                                </Box>
                                <Box className="about-information">
                                    <Typography fontSize="16px" fontWeight={600}>
                                        {t("USER_COMM_LANG")}
                                    </Typography>
                                    <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                        {generalInfo?.communicationLanguage.toLocaleUpperCase() ?? "No information."}
                                    </Typography>
                                </Box>
                                <Box className="about-information">
                                    <Typography fontSize="16px" fontWeight={600}>
                                        {t("CLIENT_AGENCY_NAME")}
                                    </Typography>
                                    <Typography fontSize="13px" sx={{ color: "#a2a0a0" }}>
                                        {generalInfo?.contactAgency?.agencyName ?? "No information."}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </ActivityLayout>
                    <Box className="session-container">
                        <Box className="session-header">
                            <Typography fontSize="20px" fontWeight={500}>{t("SESSIONS_LABEL")}</Typography>
                        </Box>
                        <ClientDetailSessionTable sessions={sessions} updateSession={handleSessionUpdate} />
                    </Box>
                    <Box className="schedule-container">
                        <ClientEvents events={events} clientId={client?.id!} />
                        <Box className="helper-list-container">
                            <Typography fontSize="18px" fontWeight={600}>
                                {t("USER_RESPONSIBLE_PEOPLE")}
                            </Typography>
                            <Box className="helper-list">
                                {client?.responsibleUsers && client!.responsibleUsers!.length > 0 ? (
                                    client!.responsibleUsers.map((element) => (
                                        <Box key={element.responsibleUserId}>
                                            <Typography>{element.responsibleUserName}</Typography>
                                        </Box>
                                    ))
                                ) : (
                                    <Typography fontSize={"14px"}>
                                        {t("CLIENT_NO_RESPONSIBLE_PEOPLE")}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    {selectedUserInstitution?.roles.some(r => ["Admin", "Field Coordinator", "Field Coordinator Light", "USZ Coordinator"].includes(r)) &&
                        <Box sx={{
                            width: {
                                sm: '100%', marginBottom: "75px"
                            },
                        }}>
                            <ClientSessionTimelineOverview />
                        </Box>}
                </Box >
            </Box>
        </>
    );
}


export default observer(ClientAbout);

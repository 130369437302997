import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Stack } from '@mui/material';
import shadows from '@mui/material/styles/shadows';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AccordionExpander from '../../../components/AccordionExpander';
import CloudFileInfo from '../../../components/CloudFileInfo';
import { ConsentStatus, InformConsent } from '../../../data/models/client';
import { SessionFile } from '../../../data/models/session';
import { useStore } from '../../../stores/store';
import { RoundButton } from '../../../_styles/StyledButtons';
import SessionFileForm from '../../Screening/Process/Session/Forms/SessionFileForm';
interface ConsentBoxProps {
    consent: InformConsent;
    getICFiles: (order: number) => SessionFile[];
    t?: (key: string) => string;
    status?: string;
}

const ConsentBox: React.FC<ConsentBoxProps> = ({ consent, getICFiles, status }) => {
    const { modalStore } = useStore();
    const { openModal } = modalStore;
    const { t } = useTranslation();

    return (
        <Box
            key={consent.order}
            sx={{
                backgroundColor: "white",
                borderRadius: "8px",
                width: "800px",
                mb: 2,
                p: 2,
                boxShadow: shadows[1],
            }}
        >
            <Stack spacing={2}>
                <CloudFileInfo
                    files={consent.fileName ? getICFiles(consent.order) : []}
                    labels={{
                        header: t(consent.order === 0 ? "SESSION_FIRST_IC" : consent.order === 1 ? "SESSION_SECOND_IC" : consent.order === 2 ? "SESSION_THIRD_IC" : "UNKNOWN_IC"),
                        emptyText: t("SESSION_NO_FILES"),
                    }}
                    canDelete={false}
                    displayStatuses={true}
                    fileType='SESSION'
                    canDownload={true}
                    consent={consent}
                />
                {consent.consentStatus === ConsentStatus.ACCEPTED && status !== "YES" && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <RoundButton
                            onClick={() =>
                                openModal(
                                    <SessionFileForm
                                        order={consent.order}
                                        uploadedFiles={getICFiles(consent.order)}
                                        sessionIdParam={consent.sessionId}
                                    />,
                                    { size: "sm" }
                                )
                            }
                            disableTouchRipple
                            sx={{ width: "fit-content" }}
                        >
                            <FileUploadOutlinedIcon sx={{ mr: "4px" }} />
                            {t("IC_UPLOAD_FILE")}
                        </RoundButton>
                    </Box>
                )}
            </Stack>
            {consent.notes &&
                <AccordionExpander title={t("GENERAL_NOTES")} description={t(consent.notes)} ></AccordionExpander>
            }
        </Box >
    );
};

export default observer(ConsentBox);

import { makeAutoObservable, runInAction } from "mobx";
import agent from "../utils/agent";
import { store } from "./store";
import { AvailableVariablesResponse, DateRange } from "../data/models/stats";

export class DataExportStore {
    loading = false;

    constructor() {
        makeAutoObservable(this)
    }

    getDataExport = async (payload: any) => {
        store.loadingStore.startLoading(this.getDataExport)
        this.loading = true;
        try {
            const result = await agent.DataExport.requestExport(payload);
            runInAction(() => {
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getDataExport)
            return result;
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getDataExport)
            throw error;
        }
    }

    getAvailableVariables = async (): Promise<AvailableVariablesResponse> => {
        store.loadingStore.startLoading(this.getAvailableVariables)
        try {
            const result = await agent.DataExport.availableVariables();
            runInAction(() => {
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getAvailableVariables)
            return result as AvailableVariablesResponse;
        } catch (error) {
            store.loadingStore.stopLoading(this.getAvailableVariables)
            throw error;
        }
    }
    getBridgeTableExport = async (institutionId: string) => {
        store.loadingStore.startLoading(this.getBridgeTableExport)
        try {
            const result = await agent.DataExport.bridgeTable(institutionId);
            store.loadingStore.stopLoading(this.getBridgeTableExport)
            return result;
        } catch (error) {
            store.loadingStore.stopLoading(this.getBridgeTableExport)
            throw error;
        }
    }

    getConsentFiles = async (institutionId: string) => {
        store.loadingStore.startLoading(this.getConsentFiles)
        try {
            const result = await agent.DataExport.exportConsentFilesData(institutionId);
            store.loadingStore.stopLoading(this.getConsentFiles)
            return result;
        } catch (error) {
            store.loadingStore.stopLoading(this.getConsentFiles)
            throw error;
        }
    }

    getSessionStats = async (institutionId: string, range?: DateRange) => {
        store.loadingStore.startLoading(this.getSessionStats);
        try {
            const result = await agent.DataExport.sessionStats(institutionId, range);
            store.loadingStore.stopLoading(this.getSessionStats);
            return result;
        } catch (error) {
            store.loadingStore.stopLoading(this.getSessionStats);
            throw error;
        }
    }

    getInterventionStats = async (institutionId: string, range?: DateRange) => {
        store.loadingStore.startLoading(this.getInterventionStats);
        try {
            const result = await agent.DataExport.interventionStats(institutionId, range);
            store.loadingStore.stopLoading(this.getInterventionStats);
            return result;
        } catch (error) {
            store.loadingStore.stopLoading(this.getInterventionStats);
            throw error;
        }
    }

    getNoEventUsers = async (institutionId: string, range?: DateRange) => {
        store.loadingStore.startLoading(this.getNoEventUsers);
        try {
            const result = await agent.DataExport.noEventStats(institutionId, range);
            store.loadingStore.stopLoading(this.getNoEventUsers);
            return result;
        } catch (error) {
            store.loadingStore.stopLoading(this.getNoEventUsers);
            throw error;
        }
    }

    getCompletedPMEvents = async (institutionId: string, range?: DateRange) => {
        store.loadingStore.startLoading(this.getCompletedPMEvents);
        try {
            const result = await agent.DataExport.pmStats(institutionId, range);
            store.loadingStore.stopLoading(this.getCompletedPMEvents);
            return result;
        } catch (error) {
            store.loadingStore.stopLoading(this.getCompletedPMEvents);
            throw error;
        }
    }

    getK10Stats = async (institutionId: string, timeline: number, range?: DateRange) => {
        store.loadingStore.startLoading(this.getK10Stats);
        try {
            const result = await agent.DataExport.k10Stats(institutionId, timeline, range);
            store.loadingStore.stopLoading(this.getK10Stats);
            return result;
        } catch (error) {
            store.loadingStore.stopLoading(this.getK10Stats);
            throw error;
        }
    }

    reset() {
        this.loading = false;
    }
}
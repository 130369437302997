import { Room, RoomFormValues } from "../data/models/room";
import agent from "../utils/agent";
import { runInAction, makeAutoObservable } from "mobx";
import { store } from "./store";

export default class RoomStore {
    rooms: Map<string, Room> = new Map<string, Room>();
    loading = false;
    editMode = false;

    constructor() {
        makeAutoObservable(this)
    }

    get roomList() {
        return Array.from(this.rooms.values());
    }

    getRooms = async (tenantId: string) => {
        store.loadingStore.startLoading(this.getRooms);
        this.loading = true;
        try {
            const listOfRooms: Room[] = await agent.Rooms.get(tenantId);
            runInAction(() => {
                this.rooms.clear();
                listOfRooms.forEach((room) => this.rooms.set(room.id, room));
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getRooms);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getRooms);
            throw error;
        }
    }

    getRoomsByInstitution = async (institutionId: string) => {
        store.loadingStore.startLoading(this.getRoomsByInstitution);
        this.loading = true;
        try {
            const listOfRooms: Room[] = await agent.Rooms.getByInstitutionId(institutionId);
            runInAction(() => {
                this.rooms.clear();
                listOfRooms.forEach((room) => this.rooms.set(room.id, room));
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getRoomsByInstitution);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getRoomsByInstitution);
            throw error;
        }
    }

    getRoom = async (id: string) => {
        store.loadingStore.startLoading(this.getRoom);
        this.loading = true;
        try {
            const room: Room = await agent.Rooms.getById(id);
            runInAction(() => {
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.getRoom);

            return room;
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getRoom);
            throw error;
        }
    }

    createRoom = async (room: RoomFormValues) => {
        store.loadingStore.startLoading(this.createRoom);
        this.loading = true;
        try {
            await agent.Rooms.create(room);
            runInAction(() => {
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.createRoom);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.createRoom);
            throw error;
        }
    }

    updateRoom = async (room: RoomFormValues) => {
        store.loadingStore.startLoading(this.updateRoom);
        this.loading = true;
        try {
            await agent.Rooms.update(room);
            runInAction(() => {
                this.rooms.set(room.id!, room as Room);
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.updateRoom);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.updateRoom);
            throw error;
        }
    }

    deleteRoom = async (id: string) => {
        store.loadingStore.startLoading(this.deleteRoom);
        this.loading = true;
        try {
            const response = await agent.Rooms.delete(id);
            runInAction(() => {
                if (response === "ROOM_DELETE") {
                    this.rooms.delete(id);
                }
                else if (response === "ROOM_DEACTIVATE") {
                    let room = this.rooms.get(id);
                    const roomCopy = { ...room }
                    roomCopy.isDeactivated = true;

                    this.rooms.set(id, roomCopy as Room);

                    store.commonStore.setCalendarViews(store.commonStore.getCalendarViews().filter(r => r.id !== roomCopy.id));
                }
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.deleteRoom);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.deleteRoom);
            throw error;
        }
    }

    activateRoom = async (id: string) => {
        store.loadingStore.startLoading(this.activateRoom);
        this.loading = true;
        try {
            await agent.Rooms.activate(id);
            runInAction(() => {
                let room = this.rooms.get(id);
                const roomCopy = { ...room }
                roomCopy.isDeactivated = false;

                this.rooms.set(id, roomCopy as Room);
                this.loading = false;
            });
            store.loadingStore.stopLoading(this.activateRoom);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.activateRoom);
            throw error;
        }
    }

    reset(): void {
        this.rooms?.clear();
        this.loading = false;
        this.editMode = false;
    }

}

import { SubType } from "../models/event";

export const brightTimelines: SubType = {
    assessment: [
        { name: "T1 (Screening/Baseline)" },
        { name: "T1 Assisted online (Screening/Baseline)" },
        { name: "T2 assisted online (3 Month)" },
        { name: "T2 in presence (3 Month)" },
        { name: "T3 assisted online (6 Month)" },
        { name: "T3 in presence (6 Month)" },
        { name: "T4 assisted online (12 Month)" },
        { name: "T4 in presence (12 Month)" },
        { name: "T5 assisted online (24 Month)" },
        { name: "T5 in presence (24 Month)" },
        { name: "T6 assisted online (36 Month)" },
        { name: "T6 in presence (36 Month)" },
    ],
    intervention: [
        { name: "PM1" },
        { name: "PM2" },
        { name: "PM3" },
        { name: "PM4" },
        { name: "PM5" },
        { name: "Booster 1" },
        { name: "Booster 2" },
        { name: "Booster 3" },
    ]
}

export const spiritTimelines: SubType = {
    assessment: [
        { name: "T1 (Screening/Baseline)" },
        { name: "T1 Assisted online (Screening/Baseline)" },
        { name: "POST_INTERVENTION assisted online" },
        { name: "POST_INTERVENTION in presence" },
        { name: "T2 assisted online (3 Month)" },
        { name: "T2 in presence (3 Month)" },
    ],
    intervention: [
        { name: "PM1" },
        { name: "PM2" },
        { name: "PM3" },
        { name: "PM4" },
        { name: "PM5" },
    ]
}

export const spiritDialogTimelines = [
    { name: "T1 (Screening/Baseline)" },
    { name: "POST_INTERVENTION assisted online" },
    { name: "POST_INTERVENTION in presence" },
    { name: "T2 assisted online (3 Month)" },
    { name: "T2 in presence (3 Month)" },
    { name: "PM1" },
    { name: "PM2" },
    { name: "PM3" },
    { name: "PM4" },
    { name: "PM5" },
]

export const brightDialogTimelines = [
    { name: "T1 (Screening/Baseline)" },
    { name: "T2 assisted online (3 Month)" },
    { name: "T2 in presence (3 Month)" },
    { name: "T3 assisted online (6 Month)" },
    { name: "T3 in presence (6 Month)" },
    { name: "T4 assisted online (12 Month)" },
    { name: "T4 in presence (12 Month)" },
    { name: "T5 assisted online (24 Month)" },
    { name: "T5 in presence (24 Month)" },
    { name: "T6 assisted online (36 Month)" },
    { name: "T6 in presence (36 Month)" },
    { name: "PM1" },
    { name: "PM2" },
    { name: "PM3" },
    { name: "PM4" },
    { name: "PM5" },
    { name: "Booster 1" },
    { name: "Booster 2" },
    { name: "Booster 3" },
]

export const reminderOptions =
    [
        { id: '1', name: '15 mins before' },
        { id: '2', name: '30 mins before' },
        { id: '3', name: '1 hour before' }
    ];

export const repeatOptions =
    [
        { id: 9, name: 'NO_RP' },
        { id: 1, name: 'MONTHLY_RP' },
        { id: 2, name: 'WEEKLY_RP' },
        { id: 3, name: 'DAILY_RP' },
    ];

export const intervals =
    [
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' }
    ];

export const assesementTimePoints = [
    { name: "Baseline", value: "Baseline" },
    { name: "Post Intervention", value: "POST_INTERVENTION" },
    { name: "T2", value: "T2" },
    { name: "T3", value: "T3" },
    { name: "T4", value: "T4" },
    { name: "T5", value: "T5" },
    { name: "T6", value: "T6" },
    { name: "PM1", value: "PM1" },
    { name: "PM2", value: "PM2" },
    { name: "PM3", value: "PM3" },
    { name: "PM4", value: "PM4" },
    { name: "PM5", value: "PM5" },
    { name: "Booster 1", value: "Booster 1" },
    { name: "Booster 2", value: "Booster 2" },
    { name: "Booster 3", value: "Booster 3" }

];
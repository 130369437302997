import { Box, CircularProgress, IconButton, LinearProgress, Link, List, ListItem, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RestorePageOutlinedIcon from "@mui/icons-material/RestorePageOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import VerifiedIcon from "@mui/icons-material/Verified"
import { CloudFile, ConsentStatus, InformConsent } from "../data/models/client";
import React, { useState } from "react";
import PendingIcon from '@mui/icons-material/Pending';
import CancelIcon from '@mui/icons-material/Cancel';
import { RoundButton } from "../_styles/StyledButtons";
import { useStore } from "../stores/store";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { DocumentOriginalityDto } from "../data/models/session";
interface CloudFileProps {
    files: CloudFile[];
    setFiles?: (files: CloudFile[]) => void;
    canDelete?: boolean;
    labels?: {
        header?: React.ReactNode;
        emptyText?: React.ReactNode;
    }
    fileType: string;
    displayStatuses: boolean;
    canDownload: boolean;
    consent?: InformConsent;
}
const statusMap = {
    [ConsentStatus.ACCEPTED]: { label: "GENERAL_ACCEPTED", icon: <CheckCircleIcon color="success" sx={{ marginLeft: "4px" }} /> },
    [ConsentStatus.PENDING]: { label: "PENDING", icon: <PendingIcon color="warning" sx={{ marginLeft: "4px" }} /> },
    [ConsentStatus.DECLINED]: { label: "DECLINED", icon: <CancelIcon color="error" sx={{ marginLeft: "4px" }} /> },
    default: { label: "Unknown", icon: null, color: null }
};

function CloudFileInfo({ files, setFiles, canDelete = true, labels, displayStatuses, fileType, canDownload = false, consent }: CloudFileProps) {
    const { clientInformConsentStore } = useStore();
    const { id } = useParams();
    const { getInformConsents, setDocumentOriginality } = clientInformConsentStore;
    const { label, icon } = (consent?.consentStatus && statusMap[consent?.consentStatus]) || {};

    const { sessionStore, institutionStore, clientStore, } = useStore();
    const { viewConsentFile, loadingFileUpload } = sessionStore;
    const { selectedUserInstitution } = institutionStore;
    const { viewTaskFile } = clientStore;

    const { t } = useTranslation();
    function formatBytes(bytes: number, decimals: number = 2) {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    const getFileStyling = (file: CloudFile) => {
        const ext = getExtension(file);

        return ext.toLowerCase();
    };

    const getExtension = (file: CloudFile) => {
        return file!.fileName.split(".").pop()!.toUpperCase();
    };

    const updateFile = (file: CloudFile, isDeleted: boolean) => {
        if (!canDelete) return;

        const updatedFiles = files.map((f) => {
            if (f.id === file.id) {
                return {
                    ...f,
                    isDeleted,
                };
            }
            return f;
        });

        setFiles && setFiles(updatedFiles);
    };

    const fetchFile = async (file: CloudFile) => {
        //setLoading(true);
        try {
            const response = fileType === "SESSION" ? await viewConsentFile(file.id, selectedUserInstitution?.institutionId!) :
                await viewTaskFile(file.id);

            const url = URL.createObjectURL(response);

            window.open(url, '_blank', 'noopener,noreferrer');
        }
        catch (error) {
            console.error('Error fetching the file:', error);
        }
        finally {
            //setLoading(false);
        }
    }

    const downloadFile = async (file: CloudFile) => {
        // setLoading(true);

        try {
            const response = await viewConsentFile(file.id, selectedUserInstitution?.institutionId!);

            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(response);
            link.download = file.fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        catch (error) {
            console.error('Error downloading the file: ', error);
        }
        finally {
            // setLoading(false);
        }
    }

    const handleVerify = async (status: string) => {

        const documentOriginalityDto: DocumentOriginalityDto = {
            status: status
        };

        if (!consent) return;
        await setDocumentOriginality(consent.fileSessionId, documentOriginalityDto);
        if (!id) return;
        await getInformConsents(id);
    }

    return (
        <Box className="file-info-container">
            <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ width: "100%" }}>
                <Typography fontWeight={500} fontSize="16px" sx={{ marginRight: "10px", alignSelf: "flex-start" }}>
                    {labels?.header ?? "Previously uploaded files"}
                </Typography>
                {displayStatuses && consent && <Stack direction="row" spacing={1} alignItems="flex-end">
                    <Box>
                        <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>{t(label || "")} {icon}</Typography>
                    </Box>
                    {consent.documentOriginal === "YES" && <Box display="flex" alignItems="center" >
                        <Typography variant="body2">Original</Typography>
                        <VerifiedIcon color="info" sx={{ marginLeft: "4px" }} />
                    </Box>
                    }
                    {consent.documentOriginal === "NO" && <Box display="flex" alignItems="center" >
                        <Typography variant="body2"> Not Original</Typography>
                        <CancelIcon color="error" sx={{ marginLeft: "4px" }} />
                    </Box>
                    }
                    {consent.documentOriginal === "PENDING" && files.length > 0 && <Box display="flex" alignItems="center">
                        Original document: 
                        <RoundButton variant="contained" sx={{ fontSize: "12px", p: 1.8, width: "4rem", marginLeft: "4px", marginRight: "4px" }} onClick={() => handleVerify("YES")}>Yes</RoundButton>
                        <RoundButton variant="contained" color="error" sx={{ fontSize: "12px", p: 1.8, width: "4rem" }} onClick={() => handleVerify("NO")}>No</RoundButton>
                    </Box>
                    }
                </Stack>
                }
            </Box >
            {
                files.length === 0 && (
                    <Typography
                        fontSize="13px"
                        sx={{ color: "#a2a2a2", marginLeft: "1rem" }}
                    >
                        {labels?.emptyText ?? "No files were uploaded this task."}
                    </Typography>
                )
            }
            {
                files.length > 0 && (
                    <List
                        className="file-info-list"
                        sx={{ padding: files.length > 0 ? "8px 0" : 0 }}
                    >
                        {files.map((file, index) => (
                            <ListItem key={index} className="file-info-item">
                                {file.fileName &&
                                    < Box
                                        className={"file-info-details " + getFileStyling(file)}
                                        sx={{ opacity: file.isDeleted ? "0.4" : "1" }}
                                    >
                                        <Typography fontSize="11px" fontWeight={600}>
                                            {getExtension(file)}
                                        </Typography>
                                    </Box>}
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    width="100%"
                                    sx={{ opacity: file.isDeleted ? "0.4" : "1" }}
                                >
                                    <Typography
                                        className="file-info-name"
                                        fontSize={"11px"}
                                        fontWeight={600}
                                        title={file.fileName}
                                        sx={{ margin: "0 7px" }}
                                    >
                                        {file.fileName}
                                    </Typography>
                                    {file.fileName &&
                                        <Box display="flex" alignItems="center" sx={{ pr: canDelete ? 0 : "8px" }}>
                                            {loadingFileUpload ? <CircularProgress size={20} /> :
                                                <>
                                                    <Link
                                                        sx={{ textDecoration: 'none', fontSize: '13px', marginRight: "5px" }}
                                                        href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            fetchFile(file);
                                                        }}
                                                    >
                                                        {t("GENERAL_VIEW")}
                                                    </Link>
                                                    {canDownload &&
                                                        <Link
                                                            sx={{ textDecoration: 'none', fontSize: '13px' }}
                                                            href="#"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                downloadFile(file);
                                                            }}
                                                        >
                                                            {t("GENERAL_DOWNLOAD")}
                                                        </Link>
                                                    }
                                                </>
                                            }
                                            <Typography fontSize={"9px"} fontWeight={400} sx={{ marginLeft: '1rem' }}>
                                                {formatBytes(file.size)}
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                                {canDelete
                                    &&
                                    <IconButton
                                        sx={{ marginLeft: "auto" }}
                                        onClick={() => updateFile(file, !file.isDeleted)}
                                        title={file.isDeleted ? "Restore" : "Delete"}
                                    >
                                        {file.isDeleted ? (
                                            <RestorePageOutlinedIcon sx={{ fontSize: "15px" }} />
                                        ) : (
                                            <CloseIcon sx={{ fontSize: "15px" }} />
                                        )}
                                    </IconButton>
                                }
                            </ListItem>
                        ))}
                    </List>
                )
            }
        </Box >
    );
}

export default observer(CloudFileInfo);

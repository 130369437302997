import { makeAutoObservable, runInAction } from "mobx";

import { AssignExistingUserToTenant, UserCreationDto, UserDto, UserStatus } from "../data/models/user";
import agent from "../utils/agent";
import { UserTenant } from "../data/models/tenant";
import { store } from "./store";

export default class UserStore {
    users: Map<string, UserDto> = new Map<string, UserDto>();
    loading: boolean = false;
    userTenants: Map<string, UserTenant> = new Map<string, UserTenant>();

    constructor() {
        makeAutoObservable(this)
    }

    get userList() {
        return Array.from(this.users.values());
    }

    get userTenantList() {
        return Array.from(this.userTenants.values());
    }

    getUsers = async (tenantId: string) => {
        store.loadingStore.startLoading(this.getUsers);
        try {
            const users = await agent.Users.get(tenantId);
            runInAction(() => {
                this.users.clear();
                users.forEach((user) => {
                    this.users.set(user.id, user);
                });
            });
            store.loadingStore.stopLoading(this.getUsers);
        } catch (error) {
            store.loadingStore.stopLoading(this.getUsers);
            throw error;
        }
    }

    getUser = async (userId: string, tenantId: string, institutionId: string) => {
        store.loadingStore.startLoading(this.getUser);
        this.loading = true;
        try {
            const user = await agent.Users.getById(userId, tenantId, institutionId);
            const institutionPermissions = await agent.Users.getAssignedPermissions(userId, tenantId);
            user.userInstitutions = institutionPermissions;

            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getUser);

            return user;
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getUser);
            throw error;
        }
    }

    checkUser = async (isntitutionId: string, email: string) => {
        store.loadingStore.startLoading(this.checkUser);
        this.loading = true;
        try {
            const user = await agent.Users.checkUser(isntitutionId, email);

            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.checkUser);

            return user;
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.checkUser);
            throw error;
        }
    }

    assignUserToTenant = async (user: AssignExistingUserToTenant) => {
        store.loadingStore.startLoading(this.assignUserToTenant);
        this.loading = true;
        try {
            await agent.Users.assignUserToTenant(user);
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.assignUserToTenant);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.assignUserToTenant);
            throw error;
        }
    }

    getAssignedPermissions = async (userId: string, tenantId: string) => {
        store.loadingStore.startLoading(this.getAssignedPermissions);
        this.loading = true;
        try {
            const institutionPermissions = await agent.Users.getAssignedPermissions(userId, tenantId);
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getAssignedPermissions);
            return institutionPermissions;
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.getAssignedPermissions);
            throw error;
        }
    }

    register = async (credentials: UserCreationDto) => {
        store.loadingStore.startLoading(this.register);
        this.loading = true;
        try {
            await agent.Users.create(credentials);
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.register);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.register);
            throw error;
        }
    }

    updateUser = async (user: UserCreationDto, institutionId: string) => {
        store.loadingStore.startLoading(this.updateUser);
        this.loading = true;
        try {
            await agent.Users.update(user, institutionId);

            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.updateUser);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.updateUser);
            throw error;
        }
    }

    deleteUser = async (userId: string, institutionId: string) => {
        store.loadingStore.startLoading(this.deleteUser);
        try {
            await agent.Users.delete(userId, institutionId);
            runInAction(() => {
                this.users.delete(userId);
                this.loading = false
            });
            store.loadingStore.stopLoading(this.deleteUser);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.deleteUser);
            throw error;
        }
    }

    reset2fa = async (userId: string, institutionId: string) => {
        store.loadingStore.startLoading(this.reset2fa);
        try {
            await agent.Users.reset2fa(userId, institutionId);
            runInAction(() => {
                this.loading = false
            });
            store.loadingStore.stopLoading(this.reset2fa);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.reset2fa);
            throw error;
        }
    }

    deactivateUser = async (userId: string, institutionId: string) => {
        store.loadingStore.startLoading(this.deactivateUser);
        try {
            await agent.Users.deactivate(userId, institutionId);
            runInAction(() => {
                this.loading = false
            });
            store.loadingStore.stopLoading(this.deactivateUser);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.deactivateUser);
            throw error;
        }
    }

    reactivateUser = async (userId: string, institutionId: string) => {
        store.loadingStore.startLoading(this.reactivateUser);
        try {
            await agent.Users.reactivate(userId, institutionId);
            runInAction(() => {
                this.loading = false
            });
            store.loadingStore.stopLoading(this.reactivateUser);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.reactivateUser);
            throw error;
        }
    }


    anonymizeUser = async (userId: string, institutionId: string) => {
        store.loadingStore.startLoading(this.anonymizeUser);
        try {
            await agent.Users.anonymize(userId, institutionId);
            runInAction(() => {
                this.loading = false
            });
            store.loadingStore.stopLoading(this.anonymizeUser);
        } catch (error) {
            runInAction(() => this.loading = false);
            store.loadingStore.stopLoading(this.anonymizeUser);
            throw error;
        }
    }

    reset() {
        this.users.clear();
        this.userTenants?.clear();
        this.loading = false;
    }
}
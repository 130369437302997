import { action, makeAutoObservable, runInAction } from "mobx";
import { NotificationResultLog } from "../data/models/notificationLog";
import { Pagination } from "../data/models/pagination";
import { DateRange } from "../data/models/stats";
import agent from "../utils/agent";
import { store } from "./store";

export default class NotificationLogsStore {
    logs: Map<string, NotificationResultLog> | undefined = new Map<string, NotificationResultLog>();
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    get logList() {
        const logList = Array.from(this.logs!.values());
        return logList.flat();
    }

    clearLogs() {
        this.logs?.clear();

    }

    getNotificationLogs = async (queryParams: { paging: Pagination }, selectedInstitution: string | null, dateRange?: DateRange, isDateChange: boolean = false, isInstitutionChanged: boolean = false) => {
        store.loadingStore.startLoading(this.getNotificationLogs);
        this.loading = true;
        try {
            if (isDateChange || isInstitutionChanged) {
                this.clearLogs();
            }
            const logsResponse = await agent.NotificationLogs.get(queryParams, selectedInstitution, dateRange);

            runInAction(() => {
                store.commonStore.setNotificationLogPagination(JSON.stringify(logsResponse.pagination));
                logsResponse.data?.forEach((log) => {
                    this.logs?.set(log.id, log);
                });
            });
            this.loading = false;
            store.loadingStore.stopLoading(this.getNotificationLogs);
        } catch (error) {
            this.loading = false;
            store.loadingStore.stopLoading(this.getNotificationLogs);
            throw error;
        }
    };
    reset() {
        this.logs?.clear();
        this.loading = false;
    }
}

import { Box, Container, IconButton, Typography } from "@mui/material";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from "@mui/lab";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";

import { format } from "date-fns";
import { observer } from "mobx-react-lite";

import { useStore } from "../../../stores/store";
import { RoundButton } from "../../../_styles/StyledButtons";
import { useTranslation } from "react-i18next";
import { getDateAndTimeFormatByPlatformLanguage } from "../../../utils/utils";

interface ClientTimelineProps {
    onPageParamsChange?: () => void;
}

function ClientTimeline({ onPageParamsChange }: ClientTimelineProps) {
    const { t, i18n } = useTranslation();
    const {
        clientStore: { timelineList },
        tenantStore: { selectedTenant },
        authStore: { user },
    } = useStore();

    const getEventIcon = (action: string) => {
        switch (action) {
            case "EVENT":
                return <CalendarTodayOutlinedIcon />;
            case "SCREENING_DATA":
            case "SURVEY_DATA":
                return <DescriptionOutlinedIcon />;
            case "MASTER_DATA":
                return <StorageOutlinedIcon />;
            case "USER_INFORMATION":
                return <AccountCircleOutlinedIcon />;
            default:
                return <EmailOutlinedIcon />;
        }
    };

    const getIconColor = (action: string) => {
        switch (action) {
            case "EVENT":
                return "#e08585"; //"#00bbf9";
            case "SCREENING_DATA":
            case "SURVEY_DATA":
                return "#ffbe0b";
            case "MASTER_DATA":
                return "#8cd0a9";
            case "USER_INFORMATION":
                return "#00bbf9";
            default:
                return "primary.main";
        }
    };

    return (
        <Container maxWidth="md" >
            <Box className="timeline-view" sx={{ flexDirection: "column" }}>
                <Box sx={{ display: "flex", paddingBottom: "5px" }}>
                    {timelineList.length > 0 ? (
                        <Typography variant="h4">{t("CLIENT_TIMELINE_TAB")}</Typography>
                    ) : (
                        <Typography variant="h4">{t("CLIENT_NO_ACTIVITY")}</Typography>
                    )}
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                    <Box className="timeline-container">
                        <Timeline>
                            {timelineList.map((activity, index) => {
                                return (
                                    <TimelineItem key={index} position="right">
                                        <TimelineOppositeContent display="none" />
                                        <TimelineSeparator>
                                            {index !== 0 ? (
                                                <TimelineConnector />
                                            ) : (
                                                <Box sx={{ flexGrow: 1 }} />
                                            )}
                                            <TimelineDot
                                                sx={{ backgroundColor: getIconColor(activity.type) }}
                                            >
                                                {getEventIcon(activity.type)}
                                            </TimelineDot>
                                            {timelineList.length - 1 !== index ? (
                                                <TimelineConnector />
                                            ) : (
                                                <Box sx={{ flexGrow: 1 }} />
                                            )}
                                        </TimelineSeparator>
                                        <TimelineContent
                                            sx={{
                                                px: 2,
                                                borderRadius: "12px",
                                                paddingLeft: "20px",
                                                margin: "5px",
                                            }}
                                        >
                                            <Box className="timeline-content">
                                                <Box sx={{ paddingBottom: "10px" }}>
                                                    <Box sx={{ position: "absolute", top: 5 }}>
                                                        <Typography variant="caption" color={"#808080"}>
                                                            {getDateAndTimeFormatByPlatformLanguage(new Date(activity.time), i18n.language)}
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="h6" component="span">
                                                            {activity.title}
                                                        </Typography>
                                                        {user?.isSuperAdmin && activity.type === "SURVEY_DATA" &&
                                                            activity.responseId && (
                                                                <IconButton
                                                                    sx={{ ml: 1 }}
                                                                    title={"View on new tab"}
                                                                    onClick={() =>
                                                                        window.open(
                                                                            `${window.location.origin}/survey/response/${activity.responseId}`
                                                                        )
                                                                    }
                                                                >
                                                                    <OpenInNewRoundedIcon sx={{ color: "#457b9d", fontSize: "18px" }} />
                                                                </IconButton>
                                                            )}
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            position: "absolute",
                                                            flexDirection: "column",
                                                        }}
                                                    >
                                                        {activity.type === "EVENT" ||
                                                            activity.type === "SURVEY_DATA" ? (
                                                            <Typography variant="body2" component="span">
                                                                {activity.eventDescription}
                                                            </Typography>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {activity.institutionName ? (
                                                            <Typography variant="body2" component="span">
                                                                {t("CLIENT_FROM_INST")}:{" "}
                                                                <Typography
                                                                    sx={{ color: getIconColor("") }}
                                                                    variant="body2"
                                                                    component="span"
                                                                >
                                                                    {activity.institutionName}
                                                                </Typography>
                                                            </Typography>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Box>
                                                    <Box>
                                                        {activity.fieldsChanged
                                                            ? Object.keys(activity.fieldsChanged).map(
                                                                (field: any) =>
                                                                    activity.fieldsChanged[field].oldValue !==
                                                                    null &&
                                                                    `${activity.fieldsChanged[field].newValue}` !==
                                                                    "" && (
                                                                        <Box key={field} sx={{ display: "flex" }}>
                                                                            <Typography variant="body2">
                                                                                <span>{field}:</span>{" "}
                                                                                <span
                                                                                    style={{ color: "#e74c3c" }}
                                                                                >{`${activity.fieldsChanged[field].oldValue}`}</span>{" "}
                                                                                {" -> "}
                                                                                <span
                                                                                    style={{ color: "#03b2ff" }}
                                                                                >{`${activity.fieldsChanged[field].newValue}`}</span>
                                                                            </Typography>
                                                                        </Box>
                                                                    )
                                                            )
                                                            : ""}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </TimelineContent>
                                    </TimelineItem>
                                );
                            })}
                        </Timeline>
                    </Box>
                    {timelineList.length > 9 && timelineList.length % 10 === 0 ? (
                        <Box sx={{ display: "flex" }}>
                            <RoundButton
                                sx={{ width: "100%", borderRadius: 0 }}
                                onClick={onPageParamsChange}
                            >
                                Load more
                            </RoundButton>
                        </Box>
                    ) : (
                        ""
                    )}
                    {/* To be added on upcoming versions
                <Box sx={{ display: 'flex' }}>
                    <TimelineFilter setFilter={setFilter} filter={filter} />
                </Box> 
                */}
                </Box>
            </Box>
        </Container>
    );
}

export default observer(ClientTimeline);

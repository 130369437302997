import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type AccordionExpanderProps = {
    title: string;
    description: string;
    iconType?: "downward" | "dropdown" | "expand";
};

const AccordionExpander: React.FC<AccordionExpanderProps> = ({
    title,
    description,
    iconType = "expand",
}) => {
    const icons: Record<string, React.ReactNode> = {
        downward: <ArrowDownwardIcon />,
        dropdown: <ArrowDropDownIcon />,
        expand: <ExpandMoreIcon />,
    };

    return (
        <Accordion sx={{
            backgroundColor: "#fff",
        }}>
            <AccordionSummary
                expandIcon={icons[iconType]}
                aria-controls={`${title.replace(/\s+/g, "-").toLowerCase()}-content`}
                id={`${title.replace(/\s+/g, "-").toLowerCase()}-header`}
            >
                <Typography component="span" variant="subtitle2">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography variant="body2">{description}</Typography>
            </AccordionDetails>
        </Accordion >
    );
};

export default AccordionExpander;

import { makeAutoObservable, runInAction } from "mobx";
import { AuditLog, AuditLogQueryParams } from "../data/models/auditLog";
import agent from "../utils/agent";
import { store } from "./store";
import { DateRange } from "../data/models/stats";

export default class AuditLogsStore {
    logs: Map<string, AuditLog> | undefined = new Map<string, AuditLog>();
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }
    get logList() {
        const logList = Array.from(this.logs!.values());
        return logList.flat();
    }

    getAuditLogs = async (queryParams: AuditLogQueryParams, dateRange?: DateRange, isDateChange: boolean = false) => {
        store.loadingStore.startLoading(this.getAuditLogs);
        this.loading = true;
        try {
            if (isDateChange) {
                this.logs?.clear();
            }

            const logsResponse = await agent.AuditLogs.get(queryParams, dateRange);

            runInAction(() => {
                store.commonStore.setAuditLogPagination(JSON.stringify(logsResponse.pagination))
                logsResponse.data.forEach((log) => {
                    this.logs?.set(log.id, log);
                });
            })
            this.loading = false;
            store.loadingStore.stopLoading(this.getAuditLogs);
        } catch (error) {
            this.loading = false;
            store.loadingStore.stopLoading(this.getAuditLogs);
            throw error;
        }
    }

    reset() {
        this.logs?.clear();
        this.loading = false;
    }
}
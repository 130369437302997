import { useEffect } from 'react';

type FetchFunction = () => Promise<any> | any | undefined;

const useFetch = (fetchFunction: FetchFunction, dependencies: any[] = []): void => {
    useEffect(() => {
        const fetchData = async () => {
            if (!fetchFunction) return;

            const result = fetchFunction();
            if (result instanceof Promise) {
                await result;
            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
};

export default useFetch;
